import axios from 'axios';
// import {setAlert} from './alertActions';


export const eventTracker = (eventName, eventType, deviceType, meta) => async (dispatch, getState) => {

    try{

        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }


        const full_url = `/api/logs`

        var session = ''
        var cdatetime = new Date()
        var location = ''
        console.log(cdatetime)

        const { data } = await axios.post(full_url,{session, cdatetime, eventName, eventType, deviceType, location, meta},config)



    
    }catch (error){

        console.log("")

    }

}



