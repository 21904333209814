import axios from 'axios'
import {setAlert} from './alertActions';
import { 
    CREATE_MODEL,
    MODEL_FAIL,
    DELETE_MODEL,
    EDIT_MODEL,
    LOAD_MODELS,

    MODEL_DETAIL,
    MODEL_DETAIL_FAIL,

    NN_DETAIL,
    NN_ADD_LAYER,
    NN_UPDATE_LAYER
} from "../constants/modelConstants"



import {
    LOAD_COURSELIST_ADMIN,
    COURSELIST_ADMIN_FAIL,
    CREATE_COURSE,
    LOAD_COURSEDETAIL_ADMIN,
    COURSEDETAIL_ADMIN_FAIL,
    UPDATE_COURSE,

    LOAD_SNIPPETSLIST_ADMIN,
    CREATE_SNIPPET,
    ADDLIST_SNIPPET,
    SNIPPETLIST_ADMIN_FAIL,
    LOAD_SNIPPETDETAIL_ADMIN,
    SNIPPETDETAIL_ADMIN_FAIL,
    ADD_SNIPPET_CARD,

    LOAD_SECTIONLIST_ADMIN,
    SECTIONLIST_ADMIN_FAIL,
    CREATE_SECTION,

    LOAD_PAGELIST_ADMIN,
    PAGELIST_ADMIN_FAIL,
    CREATE_PAGE,

    LOAD_PAGE_DETAIL,
    PAGE_DETAIL_FAIL,

    LOAD_CARDS,
    ADD_CARD,
    CARDS_FAIL,

    LOAD_LECTURELIST_ADMIN,
    LECTURELIST_ADMIN_FAIL,
    CREATE_LECTURE,

    LOAD_LECTURE_DETAIL,
    LECTURE_DETAIL_FAIL,

    LOAD_STORYLIST_ADMIN,
    ADD_STORY,
    LOAD_STORYLIST_ADMIN_FAIL,

    UPDATE_LECTURE_VIDEO,
    UPDATE_LECTURE_VIDEO_FAIL,

    LOAD_REFERENCELIST_ADMIN,
    ADD_REFERENCE,
    LOAD_REFERENCELIST_ADMIN_FAIL,

    LOAD_QUIZ_ADMIN,
    ADD_QUIZ,
    LOAD_QUIZ_ADMIN_FAIL,

    LOAD_QUESTIONS,
    ADD_QUIZ_QUESTION,
    UPDATE_QUIZ_QUESTION,
    DELETE_QUIZ_QUESTION,
    QUIZ_QUESTIONS_FAIL,

    LOAD_DATASET_ADMIN,
    ADD_DATASET,
    LOAD_DATASET_ADMIN_FAIL


   
} from "../constants/creatorConstants"


//const base_url = process.env.REACT_APP_PYBACKEND_URL


//--------------------------------------------




export const loadCoursesAdmin = (course_type) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/courses?coursetype=${course_type}`

        const { data } = await axios.get(url, config)


        console.log("Load Library ", data)

        dispatch({
            type: LOAD_COURSELIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('Courses loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: COURSELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Courses', 'danger'));


    }
}






//--------------------------------------------


export const createCourseAdmin = (title, course_url, description, instructors) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/courses`

        const { data } = await axios.post(url, {title, course_url, description, instructors}, config)


        console.log("Load Library ", data)

        dispatch({
            type: CREATE_COURSE,
            payload: data
        })
        dispatch(setAlert('Library loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: COURSELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const updateCourseAdmin = (course_id, title, course_url, description, instructors) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/courses/${course_id}`

        const { data } = await axios.put(url,{title, course_url, description, instructors} ,config)


        console.log("Load Library ", data)

        dispatch({
            type: UPDATE_COURSE,
            payload: data
        })
        dispatch(setAlert('Course Updated Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: COURSEDETAIL_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to update Course', 'danger'));


    }
}


export const loadCourseDetailAdmin = (course_id) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/courses/${course_id}`

        const { data } = await axios.get(url, config)


        console.log("Load Course ", data)

        dispatch({
            type: LOAD_COURSEDETAIL_ADMIN,
            payload: data
        })

    


        dispatch(setAlert('Course Detail loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: COURSEDETAIL_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}

// ----------------------------------------------------------------------------



export const loadSectionsAdmin = (page_type) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/nnlibrary?pagetype=${page_type}`

        const { data } = await axios.get(url, config)


        console.log("Load Library ", data)

        dispatch({
            type: LOAD_PAGELIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('Library loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: PAGELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const addCourseSection = (course_id, sectionTitle) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/courses/sections/${course_id}`

        const { data } = await axios.post(url, {sectionTitle},config)


        console.log("Add Course Sections ", data)

        dispatch({
            type: CREATE_SECTION,
            payload: data
        })
        dispatch(setAlert('Sections Loaded Successfully', 'success'));

    } catch(error){

        dispatch({
            type: SECTIONLIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Sections', 'danger'));


    }
}



// -------------------------------------------------------------------------


//---------------------------------------------------------
//Lectures

//
export const addLecture = (course_id, section_id, lectureTitle) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/courses/sections/lectures/${course_id}/${section_id}`

        const { data } = await axios.post(url, {lectureTitle},config)


        console.log("Add Lecture", data)

        dispatch({
            type: CREATE_LECTURE,
            payload: data
        })
        dispatch(setAlert('Lecture Added Successfully', 'success'));

    } catch(error){

        dispatch({
            type: LECTURELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}





export const loadLectureDetailAdmin = (lecture_id) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/lectures/${lecture_id}`

        const { data } = await axios.get(url, config)


        console.log("Load Lectures ", data)

        dispatch({
            type: LOAD_LECTURE_DETAIL,
            payload: data.lectureDetail
        })

        dispatch({
            type: LOAD_CARDS,
            payload: data.cards
        })

        dispatch(setAlert('Lecture loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LECTURE_DETAIL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Lecture', 'danger'));


    }
}



export const updateLectureVideoAdmin = (lecture_id, video_url, description) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/lectures/video/${lecture_id}`

        const { data } = await axios.post(url, {video_url, description},config)


        console.log("Update Lectures URL", data)

        dispatch({
            type: UPDATE_LECTURE_VIDEO,
            payload: data.lectureDetail
        })


        dispatch(setAlert('Lecture loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LECTURE_DETAIL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Lecture', 'danger'));


    }
}


//-------------------------------------


// ------------------------quiz ----------------------

export const addQuiz = (lecture_id, title) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/quiz/${lecture_id}`

        const { data } = await axios.post(url, {title},config)


        console.log("Reference added", data)

        dispatch({
            type: ADD_QUIZ,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: LOAD_QUIZ_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Reference', 'danger'));

    }
}





export const loadQuiz = (lecture_id) => async (dispatch, getState) => {
    
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/quiz/${lecture_id}`

        const { data } = await axios.get(url, config)


        console.log("Load Quiz", data)

        dispatch({
            type: LOAD_QUIZ_ADMIN,
            payload: data.quizDetail
        })


        dispatch({
            type: LOAD_QUESTIONS,
            payload: data.questions
        })
        dispatch(setAlert('Quiz added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_QUIZ_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load References', 'danger'));

    }
}



//---------------------------------------

export const loadSnippetsAdmin = () => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/snippets`

        const { data } = await axios.get(url, config)


        console.log("Load Snippets", data)

        dispatch({
            type: LOAD_SNIPPETSLIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('Snippets Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: SNIPPETLIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




//
export const addSnippetAdmin = (title, topic, snippet_url, hashtag) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/snippets`

        console.log("Adding : ", title, topic, snippet_url, hashtag)

        const { data } = await axios.post(url, {title, topic, snippet_url, hashtag},config)


        console.log("Create Snippet", data)

        dispatch({
            type: CREATE_SNIPPET,
            payload: data
        })
        dispatch(setAlert('Story added Successfully loaded', 'success'));


        dispatch({
            type: ADDLIST_SNIPPET,
            payload: data
        })

        
    } catch(error){

        dispatch({
            type: SNIPPETDETAIL_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load Model', 'danger'));

    }
}





//
export const addSnippetCardAdmin = (title, topic, snippet_url, hashtag) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/snippets`

        console.log("Adding : ", title, topic, snippet_url, hashtag)

        const { data } = await axios.post(url, {title, topic, snippet_url, hashtag},config)


        console.log("Create Snippet", data)

        dispatch({
            type: ADD_SNIPPET_CARD,
            payload: data
        })
        dispatch(setAlert('Story added Successfully loaded', 'success'));



        
    } catch(error){

        dispatch({
            type: SNIPPETDETAIL_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load Model', 'danger'));

    }
}




//
export const loadSnippetDetailAdmin = (snippet_id) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/snippets/${snippet_id}`

        const { data } = await axios.get(url,config)


        console.log("Load Snippet Detail", data)

        dispatch({
            type: LOAD_SNIPPETDETAIL_ADMIN,
            payload: data
        })
        dispatch(setAlert('Story added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: SNIPPETDETAIL_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load Model', 'danger'));

    }
}






//---------------------------------------

export const loadStoryline = () => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/storyline`

        const { data } = await axios.get(url, config)


        console.log("Story added", data)

        dispatch({
            type: LOAD_STORYLIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('Story added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_STORYLIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




//
export const addStory = (storydate, dateRef, storyTitle, storySummary, field, storyHashtags, storyReferences, people) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/storyline`

        const { data } = await axios.post(url, {storydate, dateRef, storyTitle, storySummary, field, storyHashtags, storyReferences, people},config)


        console.log("Story added", data)

        dispatch({
            type: ADD_STORY,
            payload: data
        })
        dispatch(setAlert('Story added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_STORYLIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load Model', 'danger'));

    }
}




// ------------------------references ----------------------

export const addReferences = (title, authors, refurl, hashtags, summary, topic, subtopic) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/references`

        const { data } = await axios.post(url, {title, authors, refurl, hashtags, summary, topic, subtopic},config)


        console.log("Reference added", data)

        dispatch({
            type: ADD_REFERENCE,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: LOAD_REFERENCELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Reference', 'danger'));

    }
}

export const loadReferences = () => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/references`

        const { data } = await axios.get(url, config)


        console.log("Story added", data)

        dispatch({
            type: LOAD_REFERENCELIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('References added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_REFERENCELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load References', 'danger'));


    }
}




// ------------------------dataset ----------------------

export const addDataset = (title, description, dataType, dataurl, problem) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/datasets`

        const { data } = await axios.post(url, {title, dataType, description, dataurl, problem},config)


        console.log("Dataset added", data)

        dispatch({
            type: ADD_DATASET,
            payload: data
        })

       
    } catch(error){


        dispatch({
            type: LOAD_DATASET_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })
    
        dispatch(setAlert('Sorry unable to add Dataset', 'danger'));

    }
}



export const loadDataset = () => async (dispatch, getState) => {
    
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/datasets`

        const { data } = await axios.get(url, config)


        console.log("Story added", data)

        dispatch({
            type: LOAD_DATASET_ADMIN,
            payload: data
        })
        dispatch(setAlert('Dataset added Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: LOAD_DATASET_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })

        dispatch(setAlert('Sorry unable to load Dataset', 'danger'));

    }
}


// ------------------- Snippets ---------------------------





export const loadPagesAdmin = (page_type) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/nnlibrary?pagetype=${page_type}`

        const { data } = await axios.get(url, config)


        console.log("Load Library ", data)

        dispatch({
            type: LOAD_PAGELIST_ADMIN,
            payload: data
        })
        dispatch(setAlert('Library loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: PAGELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const addPage = (sectionType, pageTitle) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/nnlibrary`

        const { data } = await axios.post(url, {sectionType, pageTitle},config)


        console.log("Add Library Page ", data)

        dispatch({
            type: CREATE_PAGE,
            payload: data
        })
        dispatch(setAlert('Page Modelled Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: PAGELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const loadPagesDetailAdmin = (page_id) => async (dispatch, getState) => {
    try{


        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/nnlibrary/${page_id}`

        const { data } = await axios.get(url, config)


        console.log("Load Library ", data)

        dispatch({
            type: LOAD_PAGE_DETAIL,
            payload: data.pageDetail
        })

        dispatch({
            type: LOAD_CARDS,
            payload: data.cards
        })


        dispatch(setAlert('Library loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: PAGELIST_ADMIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const addCardPage = (page_id, cardType) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/creator/cards/${page_id}`

        const { data } = await axios.post(url, {cardType}, config)


        console.log("Add Card ", data)

        dispatch({
            type: ADD_CARD,
            payload: data
        })
        dispatch(setAlert('Card added successful', 'success'));

    } catch(error){

        dispatch({
            type: CARDS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}




export const addCardItem = (page_id, card) => async (dispatch, getState) => {
    try{

        const { userState: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-Type': 'application/json',
                //Authorization: `Bearer ${userInfo.token}`
            }
        }

        const url = `/api/mlais`

        const { data } = await axios.get(url, config)


        console.log("Load Projects ", data)

        dispatch({
            type: LOAD_MODELS,
            payload: data
        })
        dispatch(setAlert('AI Models loaded Successfully loaded', 'success'));

    } catch(error){

        dispatch({
            type: MODEL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,

        })


        dispatch(setAlert('Sorry unable to load Model', 'danger'));


    }
}
