import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge } from 'react-bootstrap'

import {loadLectures} from '../actions/lectureActions'
import {loadCourses} from '../actions/courseActions'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

import Meta from '../components/Meta'

export const CourseScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

    // const lecturelist = useSelector(state => state.lecturelist)
    // const {lectures} = lecturelist


    const courselist = useSelector(state => state.courselist)
    const {courses} = courselist

    const [sectionType, setSectionType] = useState("all") 
    const [newModel, setNewModel] = useState("") 


    const selectPageRequest = (module_in) => {
        setSectionType(module_in)
        //dispatch(loadLecturesAdmin(module_in))
    }

    const navigateToPage = (pageURL) => {
        history.push(`/lectures/${pageURL}`)
    }
    

  

    useEffect(() => {
        
        //dispatch(loadLectures())

        dispatch(loadCourses())

    }, [])





    return(



        <div style={{marginTop:'0px', minHeight:'90vh'}}>


            <Meta title={'AI Lectures and Classes'} description={'Find a comprehensive collection of lectures taught on deep Learning, neural networks, convolutional networks, transformers, large language models. Find lectures on the basic prerequisite topics like matrices, probability, differentiation. Also find lectures on Python programming and Deep Learning libraries like Pytorch and Tensorflow.'}/>


            {/* <ListGroup horizontal>
                <ListGroup.Item style={{borderTop:'None', borderBottom:'None', borderLeft:'None'}}>
                    <p className='h4' style={{textAlign:'center', color:'#6b0752'}}>Courses</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("basics")} style={{backgroundColor:sectionType=='all'?'black':'white', color:sectionType=='all'?'white':'black'}}>
                    <p className='h5'>All</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("basics")} style={{backgroundColor:sectionType=='basics'?'black':'white', color:sectionType=='basics'?'white':'black'}}>
                    <p className='h5'>Deep Learning</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("python")} style={{backgroundColor:sectionType=='python'?'black':'white', color:sectionType=='python'?'white':'black'}}>
                    <p className='h5'>Coding/Python</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("maths")} style={{backgroundColor:sectionType=='maths'?'black':'white', color:sectionType=='maths'?'white':'black'}}>
                    <p className='h5'>Math for AI</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("genai")} style={{backgroundColor:sectionType=='maths'?'black':'white', color:sectionType=='maths'?'white':'black'}}>
                    <p className='h5'>Gen AI</p>
                </ListGroup.Item>
            </ListGroup> */}


        
            <ListGroup style={{border:'None'}}>
            {courses && courses.map((course, index)=>(
                <ListGroup.Item style={{padding:'0px', border:'None', marginBottom:'20px', backgroundColor:'inherit', borderRadius:'20px'}}>
                    <Link to={`/courses/${course.url}`}>
                    <Card style={{padding:'10px', borderRadius:'20px'}}>
                        
                        <Card.Header>
                             <p>
                                <span className='h4'>{course.title}</span>
                                {course.isPosted == false && (
                                    <Badge style={{backgroundColor:'#6b0752', color:'white', padding:'10px', borderRadius:'10px', marginLeft:'10px'}}>Coming Soon</Badge>
                                )}
                                
                            </p>   
                        </Card.Header>
                        <Card.Img>

                        </Card.Img>
                        <Card.Body>
                            <Row>
                                <Col>
                                {isMobile ? (
                                <img src={course.cover} alt="Neural Network Studio" style={{width:'30vw'}}/>
                                ) : (
                                <img src={course.cover} alt="Neural Network Studio" style={{width:'30vw'}}/>
                                )}
                                </Col>
                                <Col style={{minWidth:'60vw'}}>
                                    <p>Instructor : {course.instructor}</p>
                                    <p>Description : {course.description}</p>
                                    
                                </Col>
                            </Row>
                
                          
                        </Card.Body>

                        

                
                    </Card>
                    </Link>
                    
                </ListGroup.Item>
            ))}
            </ListGroup>
            

    



        </div>



    )


}

export default CourseScreen


