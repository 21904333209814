import './App.css';

import React from 'react'
//import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Container } from 'react-bootstrap'
import PrivateRoute from './routing/PrivateRoute'

import TranslateWidget from './components/utils/TranslateWidget'; // Adjust the import path as needed


import StudioScreen from './screens/StudioScreen'
import LandingScreen from './screens/LandingScreen'

import CourseScreen from './screens/CourseScreen'
import CourseDetailScreen from './screens/CourseDetailScreen'


import LectureSeriesScreen from './screens/LectureSeriesScreen'
import LectureDetailScreen from './screens/LectureDetailScreen'
import NNLibraryScreen from './screens/NNLibraryScreen'
import LibraryPageScreen from './screens/LibraryPageScreen'
import SupplementaryLecturesScreen from './screens/SupplementaryLecturesScreen'

import SnippetsScreen from './screens/SnippetsScreen'
import SnippetsDetailScreen from './screens/SnippetsDetailScreen'


import StorylineScreen from './screens/StorylineScreen'

import TermsandConditionsScreen from './screens/TermsandConditionsScreen'
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen'

//Creator routes
import AdminLoginScreen from './screens/AdminLoginScreen'
import AdminHomeScreen from './screens/AdminHomeScreen'
import AdminCoursesScreen from './screens/AdminCoursesScreen'
import AdminCourseDetailScreen from './screens/AdminCourseDetailScreen'
import AdminLectureDetailScreen from './screens/AdminLectureDetailScreen'

import AdminSnippetsScreen from './screens/AdminSnippetsScreen'
import AdminPageCreatorScreen from './screens/AdminPageCreatorScreen'


import AdminLectureCreatorScreen from './screens/AdminLectureCreatorScreen'

import AdminStorylineScreen from './screens/AdminStorylineScreen'

import AdminGrowthTrackerScreeen from './screens/AdminGrowthTrackerScreen'



//Components
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
// import AlertComponent from './components/layout/AlertComponent'


const App = () => {

  return (

    <Router>

      <Header />

      <TranslateWidget />

      {/* <AlertComponent /> */}

      <main className='py-3' style={{marginTop:'0px', paddingTop:'0px', backgroundColor:'white'}}>

        <Routes>

          <Route path='/' element={<LandingScreen />} exact />
         
          <Route path='/courses' element={<CourseScreen />} exact />
          <Route path='/courses/:course_url' element={<CourseDetailScreen />} exact />


          <Route path='/lectures' element={<LectureSeriesScreen />} exact />
          <Route path='/lectures/:lecture_id' element={<LectureDetailScreen />} exact />


          {/* <Route path='/snippets' element={<SnippetsScreen />} exact />
          <Route path='/snippets/:snippet_id' element={<SnippetsDetailScreen />} exact />


          <Route path='/library' element={<NNLibraryScreen />} exact />
          <Route path='/library/:page_id' element={<LibraryPageScreen />} exact /> */}


          {/* <Route path='/storyline' element={<StorylineScreen />} exact /> */}


          <Route path='/termsandconditions' element={<TermsandConditionsScreen />} exact />
          <Route path='/privacypolicy' element={<PrivacyPolicyScreen />} exact />




        

          {/* <Route path='/admin3123/login' element={<AdminLoginScreen />} exact />

          <Route 
          path="/admin3123/home" 
          element={
            <PrivateRoute component={AdminHomeScreen} />
          } 
        />

        <Route 
          path="/admin3123/courses" 
          element={
            <PrivateRoute component={AdminCoursesScreen} />
          } 
        />

        <Route 
          path="/admin3123/courses/:course_id" 
          element={
            <PrivateRoute component={AdminCourseDetailScreen} />
          } 
        />

        <Route 
          path="/admin3123/lectures/:lecture_id" 
          element={
            <PrivateRoute component={AdminLectureDetailScreen} />
          } 
        />

        <Route 
          path="/admin3123/storyline" 
          element={
            <PrivateRoute component={AdminStorylineScreen} />
          } 
        />

        <Route 
          path="/admin3123/snippets" 
          element={
            <PrivateRoute component={AdminSnippetsScreen} />
          } 
        /> */}




          {/* <PrivateRoute path='/admin3123/home' component={AdminHomeScreen} exact />
          <PrivateRoute path='/admin3123/lectures' component={AdminLectureScreen} exact />
          <PrivateRoute path='/admin3123/library' component={AdminLibraryScreen} exact />
          <PrivateRoute path='/admin3123/datasets' component={AdminDatasetScreen} exact />
          <PrivateRoute path='/admin3123/references' component={AdminReferenceScreen} exact />
         


          <PrivateRoute path='/admin3123/storyline' component={AdminStorylineScreen} exact />
          <PrivateRoute path='/admin3123/storyline/:story_url' component={AdminStoryScreen} exact />
          
          <PrivateRoute path='/admin3123/page/:page_url' component={AdminPageCreatorScreen} exact />
          <PrivateRoute path='/admin3123/lecture/:lecture_url' component={AdminLectureCreatorScreen} exact />
          <PrivateRoute path='/admin3123/growth' component={AdminGrowthTrackerScreeen} exact /> */}




      

        </Routes>

      </main>

      <Footer />

    </Router>

  );
}

export default App;



