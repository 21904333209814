import { combineReducers, applyMiddleware } from 'redux'
import {thunk} from 'redux-thunk'
//import { composeWithDevTools } from 'redux-devtools-extension'
//import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { configureStore } from '@reduxjs/toolkit';

import {alertReducer} from './reducers/alertReducers'

import {userReducer} from './reducers/userReducers'

import {modelListReducer, modelDetailReducer, NNDetailReducer} from './reducers/modelReducers'

import {lpagelistReducer, librarypageDetailReducer, libraryCardsReducer} from './reducers/librarypageReducers'

import {admincourselistReducer, admincourseDetailReducer, adminpagelistReducer, adminpagedetailReducer, adminpagecardsReducer, adminlecturelistReducer, 
    adminlecturedetailReducer, adminaistorylineReducer, adminReferenceReducer,
    adminDictionaryReducer, adminQuizReducer, adminQuestionReducer, 
    adminDatasetReducer, adminSnippetsReducer, adminSnippetsDetailReducer} from './reducers/creatorReducers'
 
import {languageReducer} from './reducers/languageReducers'

import {lecturelistReducer, lectureDetailReducer, lectureCardsReducer} from './reducers/lectureReducers'

import {aistorylineReducer, aiDictionaryReducer} from './reducers/storylineReducers'

import {quiztakeReducer} from './reducers/quizReducers.js'

import {dictionaryListReducer} from './reducers/dictionaryReducers.js'

import {courselistReducer, courseDetailReducer} from './reducers/courseReducers.js'

import {slcardListReducer, sltopicListReducer, learningPathReducer, slconceptnavReducer} from './reducers/snippetsReducers'



const reducer = combineReducers({

    alerts: alertReducer,

    modelList:modelListReducer, 
    modelDetail:modelDetailReducer,

    nnDetail: NNDetailReducer,

    lpagelist:lpagelistReducer, 
    librarypageDetail:librarypageDetailReducer,
    libraryCards: libraryCardsReducer,

    lecturelist:lecturelistReducer,
    lectureDetail:lectureDetailReducer, 
    lectureCards:lectureCardsReducer,

    aistoryline:aistorylineReducer, 
    aiDictionary:aiDictionaryReducer,

    quiztakeR:quiztakeReducer,

    courselist: courselistReducer, 
    courseDetail: courseDetailReducer,

    userState: userReducer,

    admincourselist: admincourselistReducer,
    admincourseDetail: admincourseDetailReducer,

    adminpagelist: adminpagelistReducer,
    adminpagedetail: adminpagedetailReducer,
    adminpagecards: adminpagecardsReducer,

    adminlecturelist:adminlecturelistReducer,
    adminlecturedetail:adminlecturedetailReducer,
    adminaistoryline:adminaistorylineReducer,
    adminReferenceR:adminReferenceReducer,

    adminDataset: adminDatasetReducer,
    dictionaryListR: dictionaryListReducer,

    adminQuiz: adminQuizReducer,
    adminQuestion: adminQuestionReducer,

    languageR:languageReducer,

    slcardList: slcardListReducer, 
    sltopicList: sltopicListReducer,
    learningPath: learningPathReducer,
    slconceptnav: slconceptnavReducer,

    adminSnippets: adminSnippetsReducer, 
    adminSnippetsDetail:adminSnippetsDetailReducer,


})



const userInfoItemsFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {

    userState: { userInfo: userInfoItemsFromStorage },

} 

// const middleware = [thunk]

// const devTools =
//   process.env.NODE_ENV === "production"
//     ? applyMiddleware(...middleware)
//     : composeWithDevTools(applyMiddleware(...middleware));

//const store = configureStore(reducer, initialState, devTools);
const store = configureStore({reducer:reducer, initialState}); //, devTools);


export default store