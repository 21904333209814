import React , {useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge } from 'react-bootstrap'

import {loadLectures, loadLectureDetail} from '../actions/lectureActions'

import {loadCourseDetail} from '../actions/courseActions'

import LectureVideo from '../components/notecards/items/LectureVideo'

import {eventTracker} from '../actions/trackerActions'


import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";

import Meta from '../components/Meta'

export const CourseDetailScreen = ({match, history}) => {

    
    const dispatch = useDispatch()

   

    //const course_url = match.params.course_url

    const {course_url}  = useParams();


    // const lecturelist = useSelector(state => state.lecturelist)
    // const {lectures} = lecturelist

    const courseDetail = useSelector(state => state.courseDetail)
    const {courseDet} = courseDetail

    const lectureDetail = useSelector(state => state.lectureDetail)
    const {lectureDet} = lectureDetail



    const [sectionType, setSectionType] = useState("basics") 
    const [newModel, setNewModel] = useState("") 

    const [selectedLecture, setSelectedLecture] = useState("")


    const [viewMode, setViewMode] = useState("lectures")
    const toggleViewMode = (mode_in) => {
        setViewMode(mode_in)
    }


    const selectPageRequest = (module_in) => {
        setSectionType(module_in)
        //dispatch(loadLecturesAdmin(module_in))
    }

    const navigateToPage = (pageURL) => {
        history.push(`/lectures/${pageURL}`)
    }

    const selectLectureVideo = (lecture_id) => {
        setSelectedLecture(lecture_id)
        dispatch(loadLectureDetail(lecture_id))

        var deviceType = isMobile ? 'mobilebrowser': 'webbrowser'
        
        dispatch(eventTracker('Lecture', 'lecture', deviceType, {lecture_id:lecture_id}))

    }

    
    

  

    useEffect(() => {
        
        dispatch(loadCourseDetail(course_url))

        dispatch(loadLectures())

        var deviceType = isMobile ? 'mobilebrowser': 'webbrowser'
        
        dispatch(eventTracker('Course Page', 'page', deviceType, {url:course_url}))

    }, [])





    return(



        <div style={{marginTop:'0px'}}>


            <Meta title={'AI Lectures and Classes'} description={'Find a comprehensive collection of lectures taught on deep Learning, neural networks, convolutional networks, transformers, large language models. Find lectures on the basic prerequisite topics like matrices, probability, differentiation. Also find lectures on Python programming and Deep Learning libraries like Pytorch and Tensorflow.'}/>


            <Card>
                <Card.Header>
                    {courseDet && (
                        <>
                        
                        <p className='h4'>Course : {courseDet.title}</p>

                        {courseDet.instructors && (
                            <p><span className='h5'>Instructor : </span><span className='h5'>{courseDet.instructors.toString()}</span></p>
                        )}
                        

                        <p><span>Course description :</span> <span className='h5'>{courseDet.description}</span></p>

                        

                        </>
                    )}
                  
                </Card.Header>
            </Card>

            {/* <InputGroup>
                <Button style={{backgroundColor:'#0C0854', paddingTop:'10px', paddingBottom:'10px', marginRight:'2px'}} onClick={()=>toggleViewMode('lectures')}>Lectures</Button>
                <Button style={{backgroundColor:'#0C0854', paddingTop:'10px', paddingBottom:'10px', marginRight:'2px'}} onClick={()=>toggleViewMode('supplementary')}>Supplementary</Button>
            </InputGroup> */}


            {isMobile ? (
                <>
                    <ListGroup style={{border:'None'}}>
                        {courseDet && courseDet.lectures && courseDet.lectures.map((lec, index)=>(
                            <ListGroup.Item style={{padding:'0px', border:'None', marginBottom:'20px'}}>
                                    
                                <Card style={{padding:'10px', borderRadius:'20px'}} onClick={()=>selectLectureVideo(lec.url)}>
                                            
                                    <Card.Header>
                                        <p>
                                            <span className='h5'>{lec.title}</span>
                                            {lec.lectureisPosted == false && (
                                                <Badge style={{backgroundColor:'#2CCCD6', color:'white', padding:'10px', borderRadius:'10px', marginLeft:'10px'}}>Coming Soon</Badge>
                                            )}
                                        </p>   
                                     </Card.Header>
                                
                                </Card>

                                {lectureDet && lectureDet.url == lec.url &&  lectureDet.lectureVideo && (
                                    <>
                                    <Card.Body>
                                    {lectureDet.description}
                                    </Card.Body>
                                    <LectureVideo video_in={lectureDet.lectureVideo} mode_in='view'/>
                                    </>
                                )}
                                    
                            </ListGroup.Item>
                        ))}
                        </ListGroup>

                
                </>
            ): (
                <>
                
                <Row>
                    <Col style={{maxWidth:'25vw', maxHeight:'90vh', overflow:'scroll'}}>


                    <ListGroup style={{border:'None'}}>
                        {courseDet && courseDet.lectures && courseDet.lectures.map((lec, index)=>(
                            <ListGroup.Item style={{padding:'0px', border:'None', marginBottom:'20px'}}>
                   
                                <Card style={{padding:'10px', borderRadius:'20px'}} onClick={()=>selectLectureVideo(lec.url)}>
                        
                                    <Card.Header>
                                        <p>
                                <span className='h5'>{lec.title}</span>
                                {lec.lectureisPosted == false && (
                                    <Badge style={{backgroundColor:'#2CCCD6', color:'white', padding:'10px', borderRadius:'10px', marginLeft:'10px'}}>Coming Soon</Badge>
                                )}
                                
                            </p>   
                        </Card.Header>
             
                        

                    </Card>
                 
                    
                </ListGroup.Item>
            ))}
            </ListGroup>
                    
                    </Col>
                    <Col style={{maxHeight:'98vh'}}>
                        {lectureDet && lectureDet.lectureVideo && (
                            <>
                             <Card.Body>
                            {lectureDet.description}
                            </Card.Body>
                            <LectureVideo video_in={lectureDet.lectureVideo} mode_in='view'/>
                            </>
                         )}
                    </Col>
                </Row>
                
             
                
                
                </>
            )
            }


    







        </div>



    )


}

export default CourseDetailScreen


